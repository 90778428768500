import { HydratedRouter } from "react-router/dom";
import { startTransition } from "react";
import { hydrateRoot } from "react-dom/client";

import getEnv from "~/utils/env";

import { hydrateI18n, I18nClientProvider } from "app/i18n/ClientEntry.remix";

async function hydrate() {
  if (getEnv().REACT_APP_MSW_MOCKED === "1") {
    await import("~/mocks/browser");
  }

  await hydrateI18n();

  startTransition(() => {
    hydrateRoot(
      document,
      <I18nClientProvider>
        {/* Toast is not working in strict mode locally due to a useEffect with side effects */}
        {/* <StrictMode> */}
        <HydratedRouter />
        {/* </StrictMode> */}
      </I18nClientProvider>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}
